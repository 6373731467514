import _ from 'lodash';
import HttpUtil from './HttpUtil';

/**
 * promise http方法
 * @param {*} settings {...} HttpUtil fetch入参
 * @param {*} settings.customErrMsg 自定义错误描述
 * @param {*} settings.isCustomErrHandle 自定义错误处理
 */
function fetch(settings) {
  const { customErrMsg, isCustomErrHandle } = settings;
  return new Promise((resolve, reject) => {
    function success(response) {
      const { retcode, retdesc } = response;
      if (retcode !== 0 && isCustomErrHandle) {
        // throw new Error(retdesc);
        reject(response);
      } else if (retcode !== 0 && !isCustomErrHandle) {
        HttpUtil.showToast('warn', retdesc);
        reject(response);
      } else {
        resolve(response);
      }
    }
    function error(e) {
      reject(e);
      if (isCustomErrHandle) {
        return;
      }
      HttpUtil.showToast(
        'warn',
        _.trim(customErrMsg) === '' ? `${_.trim(e.message || e)}` : customErrMsg,
      );
    }
    HttpUtil.fetch({ ...settings, success, error });
  });
}

export default {
  ...HttpUtil,
  fetch,
};
