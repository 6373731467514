/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-01-19 11:33:01
 * @LastEditors  : zhaoxiang
 * @LastEditTime : 2020-02-09 16:48:55
 */
import { setPublicPath } from 'systemjs-webpack-interop';
import packageConfig from '../package.json';

if (process.env.NODE_ENV !== 'production') {
  setPublicPath(packageConfig.name);
} else {
  setPublicPath(packageConfig.name, 4);
}
