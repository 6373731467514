/*
 * @Descripttion: 
 * @version: 
 * @Author: zhaoxiang
 * @Date: 2020-03-23 07:51:43
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-24 09:46:19
 */
const universityRouter = [
  {
    path: '/university/curriculum/list',
    name: 'curriculumList',
    meta: {
      title: '课程管理',
      permissionCode: 'cp-group-curriculum',
      routerType: 2,
    },
    component: () => import('../views/curriculum-manage/list/index.vue'),
  },
  {
    path: '/university/:courseId/curriculum/info/:curriculumId?',
    name: 'curriculumInfo',
    meta: {
      title: '课程详情',
      permissionCode: 'cp-group-curriculum',
      routerType: 2,
    },
    component: () => import('../views/curriculum-manage/info/index.vue'),
  },
];

export default universityRouter;
