/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-23 09:31:20
 */

import Router from 'vue-router';
import UniversityRouter from './university-router';

// const redirectPath = {
//   path: '/',
//   redirect: '/hotel/workflow',
// };

const routes = [
  // redirectPath,
  ...UniversityRouter,
];

export default new Router({
  mode: 'history',
  routes,
});
